export var mtoContentFormat = {
    TESTUM : 11,
    LESSION : 12,
    EXAM : 13,

    COURSE_NORMAL : 0,
    COURSE_CLINIC : 1,
    COURSE_EXAM : 2,
    
}
