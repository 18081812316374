require("../css/landing-common.css");
require('../css/landing_230920.css');
import {mtmStudyBuilder} from '../../../static/js/mtv/student/study/mtm-study-builder.js';
import {mtmInputColorSwitcher} from '../../../static/js/mtv/core/input/mtm-input-color-switcher.js'
import {Element} from './element.js'
var courses = [
    {subject:'수학0', school: '중등1-1', course_id : '8ea1fd502544497d9954fc4c463266b7', title: '소인수분해(N1~N4)', content_id: '93834d41-5968-495f-97e2-0d081a86d304', type: 12, img: '001.png'},
    {subject:'수학0', school: '중등1-2', course_id : '9b4400f7f7ad4442a4fe380436d7a2a8', title: '10. 자료의 정리 TEST1 (N1 ~ N5)', content_id: '99bc68a1-8397-46b9-ab89-4812a667dfe0', type: 11, img: '003.png'},
    {subject:'수학0', school: '중등2-1', course_id : '3947a72b84f2484180604af76f8db67e', title: '1. 유리수와 소수 (N1 ~ N4)', content_id: 'eb880f5b-9153-4ee9-a7b6-ebfff4c24a0a', type: 12, img: '005.png'},
    {subject:'수학0', school: '중등2-2', course_id : '3947a72b84f2484180604af76f8db67e', title: '11. 일차함수와 일차방정식의 관계 (N9 ~ N12)', content_id: 'd77ed689-f665-4052-befe-2bc7bb0e931f', type: 12, img: '007.png'},
    {subject:'수학0', school: '중등3-1', course_id : '59005c3384ac4f199e4f1567607611ef', title: '제곱근의 뜻과 성질 N1~N4', content_id: '99b17e60-c066-4f1c-9a59-7ad2b363b166', type: 12, img: '009.png'},
    {subject:'수학0', school: '중등3-2', course_id : '59005c3384ac4f199e4f1567607611ef', title: '제곱근의 뜻과 성질 TEST 02 (N5 ~ N8)', content_id: 'f26c76e7-f03d-469a-8c36-b95aebd6fb9e', type: 11, img: '011.png'},
    {subject:'수학0', school: '고등수학상', course_id : 'e9632da4c2cb40078e5c9fb34d95ae28', title: '5. 삼각함수 (N1 ~ N3)', content_id: '7224f2ce-15ab-4a31-a37a-24a65b5b18f8', type: 12, img: '013.png'},
    {subject:'수학0', school: '고등수학하', course_id : 'e9632da4c2cb40078e5c9fb34d95ae28', title: '5. 삼각함수 (N1 ~ N3)', content_id: '7224f2ce-15ab-4a31-a37a-24a65b5b18f8', type: 12, img: '015.png'},
    {subject:'수학0', school: '고등수학1', course_id : 'e9632da4c2cb40078e5c9fb34d95ae28', title: '5. 삼각함수 (N1 ~ N3)', content_id: '7224f2ce-15ab-4a31-a37a-24a65b5b18f8', type: 12, img: '017.png'},
    {subject:'수학0', school: '고등수학2', course_id : 'e9632da4c2cb40078e5c9fb34d95ae28', title: '5. 삼각함수 (N1 ~ N3)', content_id: '7224f2ce-15ab-4a31-a37a-24a65b5b18f8', type: 12, img: '019.png'},
    {subject:'수학0', school: '고등확률과통계', course_id : 'e9632da4c2cb40078e5c9fb34d95ae28', title: '5. 삼각함수 (N1 ~ N3)', content_id: '7224f2ce-15ab-4a31-a37a-24a65b5b18f8', type: 12, img: '021.png'},
    {subject:'수학0', school: '고등미적분', course_id : 'e9632da4c2cb40078e5c9fb34d95ae28', title: '5. 삼각함수 (N1 ~ N3)', content_id: '7224f2ce-15ab-4a31-a37a-24a65b5b18f8', type: 12, img: '023.png'},

    {subject:'수학1', school: '중등1-1', course_id : '8ea1fd502544497d9954fc4c463266b7', title: '소인수분해(N1~N4)', content_id: '93834d41-5968-495f-97e2-0d081a86d304', type: 12, img: '002.png'},
    {subject:'수학1', school: '중등1-2', course_id : '9b4400f7f7ad4442a4fe380436d7a2a8', title: '3. 평행선 (N9 ~ N11)', content_id: 'e44c1da4-66a0-4f62-ad42-645ea3cae922', type: 12, img: '004.png'},
    {subject:'수학1', school: '중등2-1', course_id : '3947a72b84f2484180604af76f8db67e', title: '1. 유리수와 소수 (N1 ~ N4)', content_id: 'eb880f5b-9153-4ee9-a7b6-ebfff4c24a0a', type: 12, img: '006.png'},
    {subject:'수학1', school: '중등2-2', course_id : '3947a72b84f2484180604af76f8db67e', title: '11. 일차함수와 일차방정식의 관계 (N9 ~ N12)', content_id: 'd77ed689-f665-4052-befe-2bc7bb0e931f', type: 12, img: '008.png'},
    {subject:'수학1', school: '중등3-1', course_id : '59005c3384ac4f199e4f1567607611ef', title: '제곱근의 뜻과 성질 N1~N4', content_id: '99b17e60-c066-4f1c-9a59-7ad2b363b166', type: 12, img: '010.png'},
    {subject:'수학1', school: '중등3-2', course_id : '59005c3384ac4f199e4f1567607611ef', title: '제곱근의 뜻과 성질 TEST 02 (N5 ~ N8)', content_id: 'f26c76e7-f03d-469a-8c36-b95aebd6fb9e', type: 11, img: '012.png'},
    {subject:'수학1', school: '고등수학상', course_id : 'e9632da4c2cb40078e5c9fb34d95ae28', title: '5. 삼각함수 (N1 ~ N3)', content_id: '7224f2ce-15ab-4a31-a37a-24a65b5b18f8', type: 12, img: '014.png'},
    {subject:'수학1', school: '고등수학하', course_id : 'e9632da4c2cb40078e5c9fb34d95ae28', title: '5. 삼각함수 (N1 ~ N3)', content_id: '7224f2ce-15ab-4a31-a37a-24a65b5b18f8', type: 12, img: '016.png'},
    {subject:'수학1', school: '고등수학1', course_id : 'e9632da4c2cb40078e5c9fb34d95ae28', title: '5. 삼각함수 (N1 ~ N3)', content_id: '7224f2ce-15ab-4a31-a37a-24a65b5b18f8', type: 12, img: '018.png'},
    {subject:'수학1', school: '고등수학2', course_id : 'e9632da4c2cb40078e5c9fb34d95ae28', title: '5. 삼각함수 (N1 ~ N3)', content_id: '7224f2ce-15ab-4a31-a37a-24a65b5b18f8', type: 12, img: '020.png'},
    {subject:'수학1', school: '고등확률과통계', course_id : 'e9632da4c2cb40078e5c9fb34d95ae28', title: '5. 삼각함수 (N1 ~ N3)', content_id: '7224f2ce-15ab-4a31-a37a-24a65b5b18f8', type: 12, img: '022.png'},
    {subject:'수학1', school: '고등미적분', course_id : 'e9632da4c2cb40078e5c9fb34d95ae28', title: '5. 삼각함수 (N1 ~ N3)', content_id: '7224f2ce-15ab-4a31-a37a-24a65b5b18f8', type: 12, img: '024.png'},

    {subject:'과학', school: '중등1', course_id : 'e20f7779c51e4b94aa98e832cd0b7827', title: '5-1-2) 상태 변화와 입자 배열의 변화', content_id: '0dd17ac9-be0e-419a-81f7-c804064b7060', type: 11, img: '025.png'},
    {subject:'과학', school: '중등2', course_id : '7c207fff051e4e6e9d06b42b2e1ecbe0', title: '6-1-1) 물질의 분류', content_id: '908d7530-7ee0-40c8-9fb2-9d958e1a8e84', type: 11, img: '026.png'},
    {subject:'과학', school: '중등3', course_id : '3f25b815264d4918b3749c988b99c343', title: '7-3-1) 우리은하', content_id: '842f2286-8cd3-4d9f-b3d4-02a665e34c67', type: 11, img: '027.png'},
    {subject:'과학', school: '통합과학', course_id : '3f25b815264d4918b3749c988b99c343', title: '7-3-1) 우리은하', content_id: '842f2286-8cd3-4d9f-b3d4-02a665e34c67', type: 11, img: '028.png'},
    {subject:'과학', school: '물리학1', course_id : 'e20f7779c51e4b94aa98e832cd0b7827', title: '6-3-1) 파동', content_id: '332482ea-20d4-49cd-a77e-6003db03680c', type: 11, img: '029.png'},
    {subject:'과학', school: '화학1', course_id : 'e20f7779c51e4b94aa98e832cd0b7827', title: '5-2-1) 상태 변화와 열에너지', content_id: 'ceade8f0-ac70-4be0-86c3-ad4ec4bec733', type: 11, img: '030.png'},
    {subject:'과학', school: '생명과학1', course_id : '7c207fff051e4e6e9d06b42b2e1ecbe0', title: '6-1-1) 물질의 분류', content_id: '908d7530-7ee0-40c8-9fb2-9d958e1a8e84', type: 11, img: '031.png'},
    {subject:'과학', school: '지구과학1', course_id : '7c207fff051e4e6e9d06b42b2e1ecbe0', title: '7-1-2) 수권의 활용', content_id: '3c085fb2-7fc2-4dd5-9f7c-28bc59db6f0b', type: 11, img: '032.png'},

    {subject:'영어', school: 'voca중학1영어', course_id : '26c94387fa44446c8222b0b289e2c212', title: '7. Topic 1 : Voca', content_id: '0c9aabde-d743-4cc7-9238-8d623f165f49', type: 11, img: '033.png'},
    {subject:'영어', school: 'voca중학2영어', course_id : 'b9719575864e4d2483b5c24b5597d26c', title: '6. Topic 3 : Voca', content_id: '86428172-1fda-4bcc-9b26-cb403955a3b5', type: 11, img: '034.png'},
    {subject:'영어', school: 'voca중학3영어', course_id : 'cff348215b1549e69494ccf4dfc6eaed', title: '7. Topic 1 : Voca', content_id: '74503b1c-af92-4cad-97a3-741ee1016194', type: 11, img: '035.png'},
    {subject:'영어', school: 'readon중학1영어', course_id : '26c94387fa44446c8222b0b289e2c212', title: '7. Topic 3 : Read on', content_id: '2653067b-5366-49c1-944e-fcf129f0248e', type: 11, img: '036.png'},
    {subject:'영어', school: 'readon중학2영어', course_id : 'b9719575864e4d2483b5c24b5597d26c', title: '6. Topic 3 : Read on', content_id: '3643049f-77ad-46a4-825f-2367b981ed32', type: 11, img: '037.png'},
    {subject:'영어', school: 'readon중학3영어', course_id : 'cff348215b1549e69494ccf4dfc6eaed', title: '7. Topic 3 : Read on', content_id: 'b264e27a-0a55-4259-8756-168d545442f3', type: 11, img: '038.png'},
    {subject:'영어', school: 'grammar중학1영어', course_id : '26c94387fa44446c8222b0b289e2c212', title: '7. Topic 3 : Grammar', content_id: '3dc1ae74-e760-45bc-ae2a-bb17262b7c3e', type: 11, img: '039.png'},
    {subject:'영어', school: 'grammar중학2영어', course_id : 'b9719575864e4d2483b5c24b5597d26c', title: '6. Topic 3 : Grammar', content_id: 'ba108648-f62d-4b10-b729-723217d7e33f', type: 11, img: '040.png'},
    {subject:'영어', school: 'grammar중학3영어', course_id : 'cff348215b1549e69494ccf4dfc6eaed', title: '7. Topic 3 : Grammar', content_id: 'd9607ce6-7bed-45c5-9ccf-4230ffa02973', type: 11, img: '041.png'},
    {subject:'영어', school: 'listenntalk중학1영어', course_id : '26c94387fa44446c8222b0b289e2c212', title: '7. Topic 1 : Listen & talk1 (B)', content_id: 'b1911963-f748-42b4-9c1e-12cceeb478fd', type: 12, img: '042.png'},
    {subject:'영어', school: 'listenntalk중학2영어', course_id : 'b9719575864e4d2483b5c24b5597d26c', title: '7. Topic 1 : Listen & talk1 (B)', content_id: '6b144906-85db-4e97-8d73-6afcc9e3a477', type: 12, img: '043.png'},
    {subject:'영어', school: 'listenntalk중학3영어', course_id : 'cff348215b1549e69494ccf4dfc6eaed', title: '7. Topic 1 : Listen & talk1 (B)', content_id: 'cbb6f9db-42f4-4494-a4c1-0153c048a3ef', type: 12, img: '044.png'},
    {subject:'영어', school: '어법고등1영어', course_id : '1186e334c50440dea2a8a248650e4097', title: 'I. 문장의 기초 : 1~10번', content_id: '25eaa873-8acf-4283-9845-d6849ab051db', type: 11, img: '045.png'},
    {subject:'영어', school: '독해고등1영어', course_id : 'eccfd50a882d460fa382c8a697c5d877', title: 'I. 글의 목적 파악 : 1~10번', content_id: 'f9e1cf9e-7b84-4413-9147-f7b79d6819a2', type: 11, img: '046.png'},
    

    {subject:'국어', school: '어휘한자어', course_id : '689e148e6de9484a8d12b7a7408bdf27', title: 'Day 1 어휘 (한자어)', content_id: 'fd6bf56b-0107-4e3d-b07d-feaf80b0c931', type: 11, img: '047.png'},
    {subject:'국어', school: '어휘고유어', course_id : '689e148e6de9484a8d12b7a7408bdf27', title: 'Day 1 어휘 (고유어)', content_id: '884c1598-3a00-468f-9131-0e09497e4f4e', type: 11, img: '048.png'},
    {subject:'국어', school: '사자성어', course_id : '689e148e6de9484a8d12b7a7408bdf27', title: 'Day 1 사자성어', content_id: '0c2682cc-3985-4a50-a252-3dd326d5f156', type: 11, img: '049.png'},
    {subject:'국어', school: '속담', course_id : '689e148e6de9484a8d12b7a7408bdf27', title: 'Day 1 속담', content_id: 'ae1196f0-b1a9-42fa-909f-20833ac79780', type: 11, img: '050.png'},
    {subject:'국어', school: '관용어', course_id : '689e148e6de9484a8d12b7a7408bdf27', title: 'Day 1 관용어', content_id: '9d6c9bc5-41f0-4df7-87c6-372211ccd7f1', type: 11, img: '051.png'},
    {subject:'국어', school: '어휘본문중학1국어비상', course_id : '71700d79de5e474e98dd7da7c77443cc', title: '2-1) 사계절의 땅 원천강 오늘이 어휘', content_id: '200034b1-792b-40e7-84ea-42361a8eba24', type: 11, img: '052.png'},
    {subject:'국어', school: '어휘본문중학2국어비상', course_id : '33748519a0914f07a7fcc85cd6ef2975', title: '2-3) 올바른 발음과 표기 표기, 맞춤법', content_id: '61fce28b-8bc4-4011-ae26-d9e0c6528baf', type: 11, img: '053.png'},
    {subject:'국어', school: '어휘본문중학3국어비상', course_id : '7573c559824748f2bc243e05d31d8422', title: '1-3) 플라스틱은 전혀 분해되지 않았다 본 시험', content_id: 'e824dbd9-7539-4ded-b82f-863af18da26f', type: 11, img: '054.png'},
    {subject:'국어', school: '어휘본문중학1국어천재', course_id : '6f83b94f5b094e1ca1c62b03e2e33f6e', title: '1-1-1) 딱지 어휘', content_id: '18f36353-43d4-47e1-8cda-83b380697d9c', type: 11, img: '055.png'},
    {subject:'국어', school: '어휘본문중학2국어천재', course_id : 'd9f1af28c4c7439baa9247fac77ff3f9', title: '1-1-1) 먼 후일 쪽지 시험', content_id: 'f50fdfe5-656e-46cb-b199-2cf350a64609', type: 11, img: '056.png'},
    {subject:'국어', school: '어휘본문중학3국어천재', course_id : 'd9f1af28c4c7439baa9247fac77ff3f9', title: '1-2-3) 양반전 본 시험', content_id: 'f484e611-4b58-455d-ab86-55e803c0c015', type: 11, img: '057.png'},
    {subject:'국어', school: '문법고등1국어', course_id : '2a5edb8005eb49e699bce8c1003b7adb', title: 'Ⅱ - 1. 음운의 개념 : 1 ~ 9번', content_id: '5b06042c-a2db-47fb-8545-72c8cee7cf89', type: 11, img: '058.png'},
    {subject:'국어', school: '문학고등1국어', course_id : '5b0f7b39862d4f9eac190b88b52fc97b', title: 'I - 1. 현대시 ① : 1 ~ 6번', content_id: 'e01f3168-895e-4085-9116-418ac1d2e2b1', type: 11, img: '059.png'},
    {subject:'국어', school: '독서고등1국어', course_id : 'be1c38fdbed7499593717b182b80614e', title: 'I - 1. 서양철학과 사상가 : 1 ~ 9번', content_id: '0e00e86d-111b-447d-82be-3baa2d4c17d8', type: 11, img: '060.png'},

    {subject:'기타', school: '정보엔트리', course_id : '2b8331742c9749b28b1ee43a8b3dca33', title: '4. 프로그래밍하기 - 오브젝트추가', content_id: '3b11e630-74c6-4644-b251-f6bf0f326ae5', type: 12, img: '064.png'},
    {subject:'기타', school: '파이썬', course_id : 'e85e2e795e294c7ab1bff1a4a712f9a7', title: '1-1-1 파이썬 IDLE 설치', content_id: '2512c87d-4fa9-477d-8f0d-b03bed89e752', type: 12, img: '065.png'},
    {subject:'기타', school: '중등정보', course_id : '251d5045893d440783c70c7eea9aab2c', title: '1-1-2 정보 사회와 소프트웨어', content_id: 'b346b7cc-dc9b-4af4-bfd3-b77cfac234f6', type: 12, img: '062.png'},
    {subject:'기타', school: '고등정보', course_id : '3c7173eccd3644f4a7bf7496a6726e24', title: '1-1-2 정보 과학의 발전이 미치는 영향', content_id: '4e6c331d-373d-4404-8e1e-1d3ddfee8be9', type: 12, img: '063.png'},
    {subject:'기타', school: '한국사', course_id : '720b690da3114fc2abbb09115077eed3', title: '1-1) 선사 문화의 전개와 초기 국가의 형성', content_id: '39aa8cdd-9520-4258-aabc-cd2e3a2af08d', type: 11, img: '061.png'},
    
    
]

export function ld_run()
{
    courses.forEach((course) => {
        var $element = new Element(course)

        $(`#${course.subject}`).append($element.elThis)
    })

    var slider  = new Swiper('.swiper-container', {
        slidesPerView: 1,
        observer: true,
        observeParents: true,
        pagination: {
            el: ".swiper-pagination",
            type: 'bullets'
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
        },
        // autoplay:{
        //     delay:2000,
        //     disableOnInteraction: false,
        // },
        

    });

    var color = new mtmInputColorSwitcher()

    

}


// $(document).ready(function(){
//     courses.forEach((course) => {
//         var $element = new Element(course)
//         console.log($element)
//         $('#course-list').append($element.elThis)
//     })

// })
