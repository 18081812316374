import {mtoEvents} from '../../core/utils/mto-events.js';
import {mtoVideoTime} from '../../core/utils/mto-video-time.js';

import {mtmPlayerLessonQuestion} from './mtm-player-lesson-question.js';
import {mtmPlayerLessonResultTable} from './mtm-player-lesson-result-table.js';
import {mtmPlayerLessonTitle} from './mtm-player-lesson-title.js';
import {mtmPlayerVideoContent} from './mtm-player-video-content.js';

import {mtmStudySubmitAction} from '../study/mtm-study-submit-action.js';
import {mtmPlayerLessonProgress} from './mtm-player-lesson-progress.js';
import {mtmStudyMessagePopup} from '../study/mtm-study-message-popup.js';

// clinic content 와 normal content 를 왜 굳이 구별했을까?
// playMode == contentKind  :    0 --> normal Class Content
//                              1 --> clinic content
// playMode == action_request (DB)                             
export var mtmPlayerLesson = function(options) {
    this.id = 'id-mtm-player-lesson-' + mtmPlayerLesson.id++;
    this.elThis = null;

    this.options = options;
    if(!this.options)
        this.options = {};

    this.index = -1;
    this.playMode = 0;
    
    this.clPlayerVideoContent = null;

    // this.resultList = [];
    this.resultList = [];
    this.player = {};

    this._init();
}

mtmPlayerLesson.id = 0;
mtmPlayerLesson.UnitMaxQNum = 3;

mtmPlayerLesson.prototype._createLessonTitle = function(parent) {
    var options = {};
    options.title = '레슨 제목';
    // new mtmPlayerLessonTitle
    // this.clPlayerLessonTitle = mtvComponentBuilder.build(tagList[i]["comp"],options);
    this.clPlayerLessonTitle = new mtmPlayerLessonTitle(options);
    this.clPlayerLessonTitle.elThis;

    parent.appendChild(this.clPlayerLessonTitle.elThis);
}

mtmPlayerLesson.prototype._createLessonProgress = function(parent) {
    var options = {};
    options.items = [];
    
    this.clPlayerLessonProgress = new mtmPlayerLessonProgress(options);
    parent.appendChild(this.clPlayerLessonProgress.elThis);
}

mtmPlayerLesson.prototype._createLessonPopUp = function(parent) {
    var options = {};
    this.clMessagePopUp = new mtmStudyMessagePopup(options);
    parent.appendChild(this.clMessagePopUp.elThis);
}

mtmPlayerLesson.prototype._createLessonSubmitAction = function(parent) 
{
    var options = {};
    options.items = [
            {
                enable : true,
                text : ' 이어하기',
                icon : 'fa fa-check',
                // iClass :
                btnClass : 'mtm-input-button mtm-input-button-c3',
                eventHandler:this.onContinueContentHandler.bind(this),
            },
            {
                enable : true,
                text : ' 확인',
                icon : 'fa fa-check',
                // iClass : 
                btnClass : 'mtm-input-button mtm-input-button-c3',
                eventHandler:this.onConfirmContentHandler.bind(this),
            },
            
    ];

    this.clLessonSubmitAction = new mtmStudySubmitAction(options);
    parent.appendChild(this.clLessonSubmitAction.elThis);
    // 초기 상태
    this.clLessonSubmitAction.show(false);
    this.clLessonSubmitAction.setShowList([true,false]);
}

mtmPlayerLesson.prototype._createLessonResultTable = function(parent) {
    // 레슨 결과 테이블
    var options = {};

    // 이것 안씀
    // teacher or student mode
    options.mode = this.options.modeStudent;
    options.eventCellClick = this.onCellClickHandler.bind(this);
    // if(this.options.modeStudent == 0)  // 선생님 mode 
    //     resultOptions.eventUnseenVideoClick = this.onUnseenVideoClickHandler.bind(this);

    this.clLessonResultTable = new mtmPlayerLessonResultTable(options);
    parent.appendChild(this.clLessonResultTable.elThis);

    // 초기 상태
    this.clLessonResultTable.show(false);

}

mtmPlayerLesson.prototype._createLessonQuestion = function(parent) {
    // 레슨 문제 플레이어
    var options = {};
    options.eventSubmitHandler = this.onQuestionSubmitHandler.bind(this);
    options.eventCorrectWrongHandler = this.onCorrectWrongHandler.bind(this);
    options.eventNextStepHandler = this.onNextStepHandler.bind(this);
    options.eventConfirmHandler = this.onConfirmHandler.bind(this);
    options.eventSolutionHandler = this.onEventSolutionHandler.bind(this);

    this.clPlayerLessonQuestion = new mtmPlayerLessonQuestion(options);
    parent.appendChild(this.clPlayerLessonQuestion.elThis);
    // 초기 상태
    this.clPlayerLessonQuestion.show(false);

}

mtmPlayerLesson.prototype._createLessonVideo = function(videoId,videoTime) {
    var options = {};
    var startTime = mtoVideoTime.getTime(videoTime[0]);
    var endTime = mtoVideoTime.getTime(videoTime[1]);
    options.videoId = videoId;
    options.startTime = startTime;
    options.endTime = endTime;
    options.eventVideoHandler = this.onVideoCompleted.bind(this);
    this.clPlayerVideoContent = new mtmPlayerVideoContent(options);
    this.elThis.appendChild(this.clPlayerVideoContent.elThis);
}

mtmPlayerLesson.prototype._init = function() {
    
    this.elThis = document.createElement('div');
    this.elThis.setAttribute('id',this.id);
    this.elThis.classList.add('col-12','mtm-player-lesson');

    this._createLessonTitle(this.elThis);
    this._createLessonProgress(this.elThis);
    this._createLessonPopUp(this.elThis);
    this._createLessonSubmitAction(this.elThis);
    this._createLessonResultTable(this.elThis);
    this._createLessonQuestion(this.elThis);
    
    ////////////////////////////////////////////////////////////
    // 맨마지막에 Video Player 를 놓는다.
    this.clPlayerVideo = null;
    
    // mtvEvents.on('OnCourseChange',this.createLessonVideo.bind(this));
    mtoEvents.on('OnOnePanel',this.onOnePanelHandler.bind(this));
    mtoEvents.on('OnTwoPanel',this.onTwoPanelHandler.bind(this));
    
}

//////////////////////////////// Utility Functions /////////////////////////
mtmPlayerLesson.prototype._isContentPairIndexValids = function(index) {
    // var end = false;
    // var a = 0;
    // var pairIndex = this._getContentPairIndex(index);
    // if(pairIndex[0] == -1)
    //     return pairIndex;
    // while(!end)
    {
        var pairIndex = this._getContentPairIndex(index);
        if(pairIndex[0] == -1)
            return pairIndex;
        if(this.player.lesson_unit_valids[pairIndex[0]])
        {
            if(!this.player.lesson_unit_new[pairIndex[0]].valids)
                return pairIndex;
            if(this.player.lesson_unit_new[pairIndex[0]].valids[pairIndex[1]])
                return pairIndex;
        }
        return [-1,-1];
        // index += 1;
        // a++;
        // if(a > 100)
        // {
        //     console.log('What ???');
        //     return [-1,-1];
        // }
    }    
}
// 전체 index 를 통해서 unitIndex, itemIndex 를 구함
mtmPlayerLesson.prototype._getContentPairIndexNextValids = function(index) {
    var end = false;
    // var a = 0;
    // var pairIndex = this._getContentPairIndex(index);
    // if(pairIndex[0] == -1)
    //     return pairIndex;
    while(!end)
    {
        var pairIndex = this._getContentPairIndex(index + 1);
        if(pairIndex[0] == -1)
            return pairIndex;
        if(this.player.lesson_unit_valids[pairIndex[0]])
        {
            if(!this.player.lesson_unit_new[pairIndex[0]].valids)
                return pairIndex;
            if(this.player.lesson_unit_new[pairIndex[0]].valids[pairIndex[1]])
                return pairIndex;
        }
        index += 1;
        // a++;
        // if(a > 100)
        // {
        //     console.log('What ???');
        //     return [-1,-1];
        // }
    }    
}

mtmPlayerLesson.prototype._getContentPairIndex = function(index) {
    // var contentIndex = [0,0];
    var unitIndex = 0;
    var itemIndex = 0;
    var num = 0;
    for(var i=0;i<this.player.lesson_unit_new.length;i++)
    {
        if(index >= num + this.player.lesson_unit_new[i].types.length)
        {
            num += this.player.lesson_unit_new[i].types.length;
            unitIndex++;
        }
        else
            break;

    }

    itemIndex = index - num;

    if(this.player.lesson_unit_new.length <= unitIndex)
    {
        itemIndex = -1;
        unitIndex = -1;
    }
    else if(this.player.lesson_unit_new[unitIndex].types.length <= itemIndex)
    {
        itemIndex = -1;
        unitIndex = -1;
    }

    console.log('mtmPlayerLesson > _getContentPairIndex : ', unitIndex,itemIndex);

    return [unitIndex,itemIndex];
}

// unitIndex 와 itemIndex 을 통해서 전체 index 를 구함
mtmPlayerLesson.prototype._getContentLinearIndex = function(unitIndex,itemIndex) {
    var num = 0;
    // 
    if(this.player.lesson_unit_new.length <= unitIndex)
        return this.total_content_num-1;
    
    for(var i=0;i<unitIndex;i++)
    {
        num += this.player.lesson_unit_new[i].types.length;
    }

    num += itemIndex;

    return num;
}

mtmPlayerLesson.prototype._getContentLinearIndexValids = function(unitIndex,itemIndex) {
    var num = -1;
    // console.log('mtmPlayerLesson.>>._getContentLinearIndexValids : ',unitIndex,itemIndex);
    //
    // if(this.player.lesson_unit_valids[i])
    
    for(var i=0;i<this.player.lesson_unit_new.length;i++)
    {
        if(i > unitIndex)
            break;
        if(this.player.lesson_unit_valids[i])
        {
            for(var j=0;j<this.player.lesson_unit_new[i].types.length;j++)
            {
                if((i==unitIndex) && (j>itemIndex))
                    break;
                if(!this.player.lesson_unit_new[i].valids)
                    num++;
                else if(this.player.lesson_unit_new[i].valids[j])
                    num++;
            }
        }
    }

    if(num < 0)
        num = 0;
    console.log('mtmPlayerLesson.>>._getContentLinearIndexValids : ',unitIndex,itemIndex,num);
    
    return num;
    // if(this.player.lesson_unit_new.length <= unitIndex)
    //     return this.total_content_num-1;
    
    // for(var i=0;i<unitIndex;i++)
    // {
    //     num += this.player.lesson_unit_new[i].types.length;
    // }
    
    // num += itemIndex;

    return num;
}


mtmPlayerLesson.prototype._checkProgressPoint = function() {
    // this.player.total_unit_index = 0;
    this.player.total_item_num = 0;
    this.player.study_item_num = 0;
    this.player.total_question_num = 0;
    this.player.correct_question_num = 0;
    this.player.progress = 0;
    this.player.point = 0;
    
    for(var i=0;i<this.player.lesson_unit_new.length;i++)
    {
        // unit 이 유효하면....
        if(this.player.lesson_unit_valids[i])
        {
            // 각각 Element 의 valids 가 없으면.... , 이것은 정규 코스
            if(!this.player.lesson_unit_new[i].valids)
            {
                this.player.total_item_num += this.player.lesson_unit_new[i].types.length;

                for(var j=0;j<this.player.lesson_unit_new[i].types.length;j++)
                {
                    if(this.player.lesson_unit_new[i].types[j] == 'q')
                    {
                        this.player.total_question_num++;
                    }

                    
                }

                // 이런 일은 없다.
                if(!this.player.lesson_result_new[i])
                        this.player.lesson_result_new.push({result:[],repeat:[]})
                for(var j=0;j<this.player.lesson_result_new[i].result.length;j++)
                {
                    this.player.study_item_num++;
                    if(this.player.lesson_unit_new[i].types[j] == 'q' &&
                        this.player.lesson_result_new[i].result[j] == 'O')
                        this.player.correct_question_num++;
                }
            }
            // 각각 Element 의 valids 가 있으면 .... , 이것은 추천 코스
            else
            {
                for(var j=0;j<this.player.lesson_unit_new[i].types.length;j++)
                {
                    if(this.player.lesson_unit_new[i].valids[j])
                    {
                        this.player.total_item_num++;
                        if(this.player.lesson_unit_new[i].types[j] == 'q')
                            this.player.total_question_num++;
                    }
                        
                }

                // 이런 일은 없다.
                if(!this.player.lesson_result_new[i])
                        this.player.lesson_result_new.push({result:[],repeat:[]})
                
                for(var j=0;j<this.player.lesson_result_new[i].result.length;j++)
                {
                    if(this.player.lesson_unit_new[i].valids[j])
                    {
                        // this.player.study_item_num++;
                        if(this.player.lesson_unit_new[i].types[j] == 'q' && this.player.lesson_result_new[i].result[j] == 'O')
                            this.player.correct_question_num++;
                        // if(this.player.testum_result_new[i].result[j] != '?')
                        this.player.study_item_num++;
                    }
                        
                }
            }
        }

    }

    if(this.player.total_item_num > 0)
        this.player.progress = parseInt(this.player.study_item_num*100/this.player.total_item_num);
    if(this.player.total_question_num > 0)
        this.player.point = parseInt(this.player.correct_question_num*100/this.player.total_question_num);
    
    // console.log('mtmPlayerLesson > _checkProgressPoint : total_item_num = ',this.player.total_item_num,
    //                     ', study_item_num =  ',this.player.study_item_num,
    //                     ', total_question_num = ', this.player.total_question_num, 
    //                     ', correct_question_num = ', this.player.correct_question_num,
    //                     ', progress = ', this.player.progress,
    //                     ', point = ', this.player.point);
}

//////////////////////////////// Utility Functions /////////////////////////
// Show On/Off Functions
mtmPlayerLesson.prototype._showPlayer = function(bShow) {
    if(bShow)
    {
        if(this.player.lesson_unit[this.player.currentUnitIndex].content_list[this.player.currentItemIndex].video == '1')
        {
            // Show Video Content
            this.clPlayerLessonQuestion.show(false);
            if(this.clPlayerVideoContent)
                this.clPlayerVideoContent.show(true);
        }
        else // Show Question Content
        {
            if(this.clPlayerVideoContent)
                this.clPlayerVideoContent.show(false);
            this.clPlayerLessonQuestion.show(true);
        }
    }
    else
    {
        this.clPlayerLessonQuestion.show(false);
            if(this.clPlayerVideoContent)
                this.clPlayerVideoContent.show(false);
    }
}

mtmPlayerLesson.prototype._showVideo = function(bShow) {
    if(bShow)
        this.clPlayerVideoContent.show(true);
    else
        this.clPlayerVideoContent.show(false);
}

mtmPlayerLesson.prototype._showQuestion = function(bShow) {
    if(bShow)
        this.clPlayerLessonQuestion.show(true);
    else
        this.clPlayerLessonQuestion.show(false);
}

mtmPlayerLesson.prototype._showResult = function(bShow) {
    if(bShow)
    {
        // if(this.options.modeStudent == 1)  // student mode
            this.clLessonSubmitAction.show(true);
        
        this.clPlayerLessonProgress.show(true);
        // 표를 보여준다.
        // 여기서 무엇을 해야 하나를 선택할 수 있게 한다.
        this.clLessonResultTable.show(true);
    }
    else
    {
        this.clLessonSubmitAction.show(false);
        this.clPlayerLessonProgress.show(true);
        // 표를 보여준다.
        // 여기서 무엇을 해야 하나를 선택할 수 있게 한다.
        this.clLessonResultTable.show(false);
    }
}

////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
mtmPlayerLesson.prototype._updateLessonResult = function(bCorrect) {
    // this.player.currentIndexTotal;
    // this.player.currentItemIndex;
    // this.player.currentUnitIndex;
    var value = 'X';
    if(bCorrect)
        value = 'O';

    var itemIdx = this.player.currentItemIndex;
    var unitIdx = this.player.currentUnitIndex;

    console.log('mtmPlayerLesson.>>._updateLessonResult : ',unitIdx ,itemIdx);
    // this.player.currentIndexTotal = this._getContentLinearIndex(this.player.currentItemIndex,this.player.currentUnitIndex);

    // _checkTestumGrade : 테스트에서는 이렇게 한다.
    // var results = this.player.testum_result_new;
    // var units = this.player.testum_unit_new;
    // var unitIndex = this.player.currentUnitIndex;

    // if(units.length <= 0)
    //     return;

    // // 결과가 없으면, 초기(empty) 결과 준비
    // if(results.length <= unitIndex) // 결과가 없으면
    // {
    //     var data = {repeat:[],result:[],first:[],second:[]};
    //     for(var i=0; i < units[unitIndex].types.length;i++)
    //     {
    //         data.repeat.push(0);
    //         data.result.push('');
    //         // data.first.push('');
    //         // data.second.push('');
    //     }
    //     results.push(data);
    // }

    // Valids 를 생각하고 해야 한다.
    // update result
    if(unitIdx < this.player.lesson_result_new.length)
    {
        // 기존 result : 아직 판단하기 이름
        if(itemIdx < this.player.lesson_result_new[unitIdx].result.length)
        {
            // 기존 result : 확실
            // 새로운 result
            this.player.lesson_result_new[unitIdx].result[itemIdx] = value;
            this.player.lesson_result_new[unitIdx].repeat[itemIdx]++;
        }
        else
        {
            // 새로운 result
            for(var i=0;i<itemIdx+1;i++)
            {
                this.player.lesson_result_new[unitIdx].result.push("");
                this.player.lesson_result_new[unitIdx].repeat.push(0);
                if(itemIdx < this.player.lesson_result_new[unitIdx].result.length)
                {
                    this.player.lesson_result_new[unitIdx].result[itemIdx] = value;
                    this.player.lesson_result_new[unitIdx].repeat[itemIdx] = 1;
                    break;
                }
            }

            // this.player.lesson_result_new[unitIdx].result.push(value);
            // this.player.lesson_result_new[unitIdx].repeat.push(1);
        }
    }
    else
    {
        // 새로운 result
        for(var i=0;i<unitIdx+1;i++)
        {
            var result_data = {result:[], repeat:[]};
            this.player.lesson_result_new.push(result_data);
            if(unitIdx < this.player.lesson_result_new.length)
                break;
            // var result_data = {result:[], repeat:[]};
            // this.player.lesson_result_new.push(result_data);
        }
        
        if(itemIdx < this.player.lesson_result_new[unitIdx].result.length)
        {
            // this.player.lesson_result_new[unitIdx].result.push(value);
            // this.player.lesson_result_new[unitIdx].repeat.push(1);
            this.player.lesson_result_new[unitIdx].result[itemIdx] = value;
            this.player.lesson_result_new[unitIdx].repeat[itemIdx] = 1;
        }
        else
        {
            for(var i=0;i<itemIdx+1;i++)
            {
                this.player.lesson_result_new[unitIdx].result.push("");
                this.player.lesson_result_new[unitIdx].repeat.push(0);

                if(itemIdx < this.player.lesson_result_new[unitIdx].result.length)
                {
                    this.player.lesson_result_new[unitIdx].result[itemIdx] = value;
                    this.player.lesson_result_new[unitIdx].repeat[itemIdx] = 1;
                    break;
                }
                    
            }
            // this.player.lesson_result_new[unitIdx].result.push(value);
            // this.player.lesson_result_new[unitIdx].repeat.push(1);
        }
        

        

        // this.player.lesson_result_new[unitIdx].result.push(value);
        // this.player.lesson_result_new[unitIdx].repeat.push(1);
    }

    if(this.player.currentIndexTotal == this.player.finalIndexTotal)
    {
        // 자동 플레이로 간다.
        this.player.autoPlay = true;

    }
    // Todo. Jstar Bug. Fixed . 새로운 학습 혹은 기존의 review
    this._checkProgressPoint();

    var eData = {};
    eData.content_id = this.player.content_id;
    eData.student_id = this.player.student_id;
    eData.course_id = this.player.course_id;
    eData.course_id = this.player.course_id;
    eData.class_id = this.player.class_id;
    eData.results = this.player.lesson_result_new;
    eData.progress = this.player.progress;
    eData.point = this.player.point;

    // mtoEvents.emit('OnUpdateLessonResult',eData);
    
    this._urlUpdateLessonResultInfo();

    // 아래 두개는 같은 기능의 코드
    this.infoResult = this.clLessonResultTable.setLessonResultList(this.player.lesson_unit_valids,this.player.lesson_unit_new,this.player.lesson_result_new,false);
    // this.currentIndexTotal = this._getContentLinearIndex(this.infoResult.iLastIndexUnit,this.infoResult.iLastIndexItem);
}


// this.player.lesson_content_new : content detail
// [
//  [{v},{q},{q}],  unit
//  [{v},{q},{q}],  unit
//  [{v},{q},{q}],  unit
// ]
// this.player.lesson_unit_new : Unit
// contents : [
//  [v,q,q],  unit
//  [v,q,q],  unit
//  [v,q,q],  unit
// ]
// types : [
//  [v,q,q],  unit
//  [v,q,q],  unit
//  [v,q,q],  unit
// ]
// this.player.lesson_result_new : Result
// 
// Content Detail 과 Unit 과 Result 를 통하여 

// 1) 마지막 play content 의 unit index , item index , total index
// 2) 
mtmPlayerLesson.prototype._prepareResult = function() {
    
    this.player.finalIndexTotal = 0;
    this.player.finalUnitIndex = 0;
    this.player.finalItemIndex = 0;

    this.player.finalProcessIndex = 0;
    
    if(this.player.lesson_result_new.length == 0)
    {
        this.player.finalProcessIndex = -1;
    }

    // 정답 문제 갯수
    this.total_correct_question_num = 0;
    this.total_question_num = 0;

    this.infoResult = this.clLessonResultTable.setLessonResultList(this.player.lesson_unit_valids,this.player.lesson_unit_new,this.player.lesson_result_new,true);
    this.player.finalUnitIndex = this.infoResult.iLastIndexUnit;
    this.player.finalItemIndex = this.infoResult.iLastIndexItem;

    this.player.finalIndexTotal = this._getContentLinearIndex(this.player.finalUnitIndex,
        this.player.finalItemIndex);

    // var pairIndex = this._getContentPairIndexNextValids(this.player.finalIndexTotal);
    // var pairIndex = this._getContentPairIndexNextValids(this.player.finalIndexTotal);
    var pairIndex = this._isContentPairIndexValids(this.player.finalIndexTotal);
    if(pairIndex[0] != -1)
        this.clLessonResultTable.setLessonResultListNext(this.player.lesson_unit_valids,this.player.lesson_unit_new,this.player.lesson_result_new,pairIndex);
    else
    {
        pairIndex = this._getContentPairIndexNextValids(this.player.finalIndexTotal);
        if(pairIndex[0] != -1)
            this.clLessonResultTable.setLessonResultListNext(this.player.lesson_unit_valids,this.player.lesson_unit_new,this.player.lesson_result_new,pairIndex);
        
    }

    // if(pairIndex[0] != -1)
    // {
    //     // this.infoResult = this.clLessonResultTable.setLessonResultList(this.player.lesson_unit_valids,this.player.lesson_unit_new,this.player.lesson_result_new,true);
    //     // this.player.finalUnitIndex = this.infoResult.iLastIndexUnit;
    //     // this.player.finalItemIndex = this.infoResult.iLastIndexItem;
    // }    
    
}

mtmPlayerLesson.prototype._prepareTitle = function(title) {
    this.clPlayerLessonTitle.setTitle(title);
}

mtmPlayerLesson.prototype._prepareProgress = function() {
    this.player.finalIndicatorTotal = [];

    for(var i=0;i<this.player.lesson_unit_new.length;i++)
    {
        // 
        if(!this.player.lesson_unit_valids[i])
            continue;
        for(var j=0;j<this.player.lesson_unit_new[i].types.length;j++)
        {
            if(j > mtmPlayerLesson.UnitMaxQNum)
                    break;
                
            // check valids
            if(!this.player.lesson_unit_new[i].valids)
            {
                if(this.player.lesson_unit_new[i].types[j] == 'v')
                    this.player.finalIndicatorTotal.push(0); // video
                else
                    this.player.finalIndicatorTotal.push(1); // question
            }
            else if(this.player.lesson_unit_new[i].valids[j])
            {
                if(this.player.lesson_unit_new[i].types[j] == 'v')
                    this.player.finalIndicatorTotal.push(0); // video
                else
                    this.player.finalIndicatorTotal.push(1); // question
            }
            
        }
    }
    
    this.player.finalIndexTotal = this._getContentLinearIndex(this.player.finalUnitIndex,this.player.finalItemIndex);
    this.player.currentIndexTotal = this.player.finalIndexTotal;
    console.log('mtmPlayerLesson > _prepareProgress : ',this.player);
    
    this.clPlayerLessonProgress.setProgressContent(this.player.finalIndicatorTotal,1);
    // var minusValid = this._getValidsTotals(this.player.finalIndexTotal);
    this.player.finalIndexTotalValid = this._getContentLinearIndexValids(this.player.finalUnitIndex,this.player.finalItemIndex);
    this.clPlayerLessonProgress.setActive(this.player.finalIndexTotalValid);

}

mtmPlayerLesson.prototype._wrongStep = function() {

    // Study Result Update 조정
    this._updateLessonResult(false);
    
    this.clPlayerLessonQuestion.setMark(false);

    var rc = this.player.lesson_result_new[this.player.currentUnitIndex].repeat[this.player.currentItemIndex];
    // console.log('mtmPlayerLesson > _wrongStep 1: ',this.player.current_rc_list,rc,this.player.currentItemIndex);
    // var rc = 0;
    if(rc > 1)
    {
        // 오답하기/다음하기
        this.clPlayerLessonQuestion.setShowSubmitList([false,true,true,false]);
        // 해답/해설 보기...
        this.clPlayerLessonQuestion.showSolution(true);
    }
    else
    {
        this.clPlayerLessonQuestion.setShowSubmitList([false,true,false,false]);
        // 해답/해설 보기...
        // this.clPlayerLessonQuestion.showSolution(false);
    }

    return;
}

mtmPlayerLesson.prototype._continueStep = function() {

    console.log('mtmPlayerLesson > _continueStep', this.player.currentUnitIndex, this.player.currentItemIndex,this.player.currentIndexTotal);
    // this.player.currentIndexTotal = this._getContentLinearIndex(this.player.currentUnitIndex, this.player.currentItemIndex);
    // var contentPairIndex = this._getContentPairIndex(this.player.currentIndexTotal+1);
    var contentPairIndex = this._getContentPairIndexNextValids(this.player.currentIndexTotal);

    console.log('mtmPlayerLesson > _continueStep',contentPairIndex);
    // console.log('mtmPlayerLesson > _continueStep',this.player.currentIndexTotal,this.player.finalIndexTotal);
    
    if((this.player.currentIndexTotal < this.player.finalIndexTotal) || contentPairIndex[0] == -1)
    {
        // lesson completed !!!
        // 이어하기 없애기
        this._showPlayer(false);
        this._showResult(true);
        return;
    }

    this.player.currentUnitIndex = contentPairIndex[0];
    this.player.currentItemIndex = contentPairIndex[1];

    this.player.currentIndexTotal = this._getContentLinearIndex(this.player.currentUnitIndex,this.player.currentItemIndex)
    if(this.player.finalIndexTotal < this.player.currentIndexTotal )
        this.player.finalIndexTotal = this.player.currentIndexTotal;
    
    this._playLessonContent();
    
    return;
}

mtmPlayerLesson.prototype._correctStep = function() {
    
    // Study Result Update 조정
    this._updateLessonResult(true);
    // 
    this.clPlayerLessonQuestion.setMark(true);
    // "다음하기"만 보여 준다.
    this.clPlayerLessonQuestion.setShowSubmitList([false,false,true,false]);
    this.clPlayerLessonQuestion.showSolution(true);
    return;

}

mtmPlayerLesson.prototype._questionSubmitLesson = function() {
    // 
    
    // 답을 더는 못한다.
    this.clPlayerLessonQuestion.setAnswerEnable(false);
    // 분명히 문제 이어야만 한다.
    if(this.player.lesson_unit_new[this.player.currentUnitIndex].types[this.player.currentItemIndex] == 'q')
    {
        var answer = this.player.lesson_content_new[this.player.currentUnitIndex][this.player.currentItemIndex].answer;
        
        // 사라지기 전에, (display == none) 하기 전에
        // Exam 에서 무슨 문제가 있을까?
        var value = this.clPlayerLessonQuestion.getAnswer();
        console.log('mtmPlayerLesson > _questionSubmitLesson : answer = ', answer, ' , value =', value);
        if(answer == value)
            this._correctStep();
        else
            this._wrongStep();
    }
}

mtmPlayerLesson.prototype._videoContinueLesson = function() {
    // 이게 어떤 종류의 Video Play 인지 ?
    if(this.player.bSolutionPlay)
    {
        console.log('mtmPlayerLesson > _videoContinueLesson : this.player.bSolutionPlay');
        this.player.bSolutionPlay = false;
        this.stopLesson();
        this._showVideo(false);
        this._showQuestion(true);        
    }
    // 순차적인 Video Content Play 인가?
    else if(this.player.finalIndexTotal == this.player.currentIndexTotal)
    {
        console.log('mtmPlayerLesson > _videoContinueLesson : this.player.finalIndexTotal == this.player.currentIndexTotal');
        console.log('this.player.currentUnitIndex',this.player.currentUnitIndex);
        console.log('this.player.currentItemIndex',this.player.currentItemIndex);
        // 단계 진행
        this.total_progress_num++;
        // this.player.currentIndexTotal++;
        this._updateLessonResult(true);
        this._continueStep();
    }
    else
    {
        console.log('mtmPlayerLesson > _videoContinueLesson :', this.player.finalIndexTotal , this.player.currentIndexTotal);
        
        this._showVideo(false);
        this._showResult(true);
    }

}

mtmPlayerLesson.prototype._startVideo = function() {
    console.log('mtmPlayerLesson > _startVideo : ', this.player);

    this.clLessonSubmitAction.show(false);
    this.clPlayerLessonQuestion.show(false);
    this.clLessonResultTable.show(false);

    if(!this.clPlayerVideoContent)
        this._createLessonVideo(this.player.videoId,this.player.videoTime);
    
    this.clPlayerVideoContent.play(this.player.videoId,this.player.videoTime);

    this.clPlayerVideoContent.show(true);

    // 최종 Progress 와 현재 Progress 를 차별화 해야 되겠다.
    this.player.finalIndexTotalValid = this._getContentLinearIndexValids(this.player.currentUnitIndex,this.player.currentItemIndex);
    
    this.clPlayerLessonProgress.setActive(this.player.finalIndexTotalValid);
}

mtmPlayerLesson.prototype._beforeStartQuestion = function() {
    // Hide Others
    if(this.clPlayerVideoContent)
        this.clPlayerVideoContent.show(false);
    this.clLessonResultTable.show(false);
    this.clLessonSubmitAction.show(false);
}

mtmPlayerLesson.prototype._startQuestion = function() {
    
    this._beforeStartQuestion();
    // 문제 번호 만들기  1-1, 1-2, ...
    var no = (this.player.currentUnitIndex + 1) + '-' ;

    if(this.player.lesson_unit_new[this.player.currentUnitIndex].types[0] == 'v')
        no += (this.player.currentItemIndex);   // 대부분은 이곳 으로
    else
        no += (this.player.currentItemIndex+1);

    // lesson_content_list_new
    // this.player.lesson_content_new : content detail
    // [
    //  [{v},{q},{q}],  unit
    //  [{v},{q},{q}],  unit
    //  [{v},{q},{q}],  unit
    // ]
    // this.player.lesson_unit_new : Unit
    // contents : [
    //  [v,q,q],  unit
    //  [v,q,q],  unit
    //  [v,q,q],  unit
    // ]
    // types : [
    //  [v,q,q],  unit
    //  [v,q,q],  unit
    //  [v,q,q],  unit
    // ]
    var q_item = this.player.lesson_content_new[this.player.currentUnitIndex][this.player.currentItemIndex];

    // 'video': '0',
    // 'id':str(questionAtom.id.hex), # uuid exclude '-' 
    // 'content' : questionAtom.content,
    // "style": questionAtom.style, 
    // "level": questionAtom.level,
    // "answer":questionAtom.answer,
    // "solution_id": questionAtom.sol_text,
    // "video_id": questionAtom.sol_video,
    // "sol_video":sol_video, // array - 멀티
    // "sol_text":sol_text,   // array - 멀티

    this.player.question_item = {
        no : no,
        content : q_item.content,
        id : q_item.id,
        style : q_item.style,
        level : q_item.level,
        answer : q_item.answer,
        // only text solution id 
        solution_id : q_item.solution_id,
        // only video solution id 
        video_id : q_item.video_id,
        // multi video solution 
        // sol_video : q_item.sol_video,
            // 'url':video_solution.url,
            // 'title':video_solution.title,
            // 'time':video_solution.time,
        // multi text solution
        // sol_text : q_item.sol_text,
            // 'content' : sol_text.content,
            // 
    };
    
    // var json_info = JSON.parse(q_item.json_data);
    // 문제를 셋팅한다.
    this.clPlayerLessonQuestion.setQuestion(this.player.question_item);
    this.player.solution_item = {
        // multi video solution 
        sol_video : q_item.sol_video,
        sol_text : q_item.sol_text,
        // sol_video : json_info.sol_video,
        // sol_text : json_info.sol_text,
         
    };

    this.clPlayerLessonQuestion.setSolution(this.player.solution_item);
    
    this.clPlayerLessonQuestion.showMark(false);  // 이문제는 새로풀어야 하고,
    this.clPlayerLessonQuestion.setAnswer('');
    this.clPlayerLessonQuestion.showSolution(false);
    // 답을 할 수 있게 한다.
    this.clPlayerLessonQuestion.setAnswerEnable(true);
    
    var qr = this.player.lesson_result_new[this.player.currentUnitIndex].result[this.player.currentItemIndex];
    var rc = this.player.lesson_result_new[this.player.currentUnitIndex].repeat[this.player.currentItemIndex];
    
    if(qr == 'O')
    {
        // console.log('mtmPlayerLesson > _startQuestion qr == O');
        this.clPlayerLessonQuestion.setMark(true);  // 이문제는 맞췄고,
        this.clPlayerLessonQuestion.setAnswer(q_item.answer);     // 정답으로 셋팅한다.
        this.clPlayerLessonQuestion.showSolution(true); // 해설을 볼수 있고,
        // 제출 , 오답 하기 , 다음 하기 , 확인 (확인)
        this.clPlayerLessonQuestion.setShowSubmitList([false,false,false,true]);    // 확인 Action 만...
        this.clPlayerLessonQuestion.setAnswerEnable(false); // 답을 할 수 없고,
    }
    else
    {
        // console.log('mtmPlayerLesson > _startQuestion else');
        // 제출 , 오답 하기 , 다음 하기 , 확인 (제출)
        this.clPlayerLessonQuestion.setShowSubmitList([true,false,false,false]);
    }    
    
    this.clPlayerLessonQuestion.show(true);

    // 최종 Progress 와 현재 Progress 를 차별화 해야 되겠다.
    this.player.finalIndexTotalValid = this._getContentLinearIndexValids(this.player.currentUnitIndex,this.player.currentItemIndex);
    
    this.clPlayerLessonProgress.setActive(this.player.finalIndexTotalValid);
}

mtmPlayerLesson.prototype._checkValidsContents = function() {
    // 기본 코스는 없다.
    // if(!this.player.lesson_unit_new[this.player.currentUnitIndex].valids)
    //     return true;

    var retVal = false;
    var unitIndex = this.player.currentUnitIndex;
    var itemIndex = this.player.currentItemIndex;
    for(var i=unitIndex;i<this.player.lesson_unit_new.length;i++)
    {
        for(var j=itemIndex;j<this.player.lesson_unit_new[i].types.length;j++)
        {
            // 기본은 항상 true, 추천이면, 없을수 있다. 
            if(!this.player.lesson_unit_valids[i])
                continue;
            this.player.currentUnitIndex = i;
            this.player.currentItemIndex = j;
            
            // 기본은 없을 수 있다.
            if(!this.player.lesson_unit_new[i].valids)
            {
                return true;
            }
            
            if(this.player.lesson_unit_new[i].valids[j])
                return true;
        }
        itemIndex = 0;
    }
    
    return retVal;
}

mtmPlayerLesson.prototype._playLessonContent = function() {
    console.log('mtmPlayerLesson > _playLessonContent :', this.player.currentUnitIndex,this.player.currentItemIndex);

    // _checkValids
    if(!this._checkValidsContents())
        return ;

    if(this.player.lesson_content_new[this.player.currentUnitIndex].length > 0)
    {
        // console.log('mtmPlayerLesson > _playLessonContent : ',this.player.lesson_unit[this.player.currentUnitIndex].content_list);
        if(this.player.lesson_content_new[this.player.currentUnitIndex][this.player.currentItemIndex].video == '1')
        {
            this.player.videoTime = this.player.lesson_content_new[this.player.currentUnitIndex][this.player.currentItemIndex].time;
            this.player.videoId = this.player.lesson_content_new[this.player.currentUnitIndex][this.player.currentItemIndex].url;
            this.player.bSolutionPlay = false;
            this._startVideo();
        }
        else
        {
            this._startQuestion();
        }
    }
}

////////////////////////////////////////////////////////////////////////
/////////////////////////////// Handler ////////////////////////////////
mtmPlayerLesson.prototype.onOnePanelHandler = function()
{
    console.log('mtmPlayerLesson > onOnePanelHandler');
    // this.elExitButton.style.display = '';
}    

mtmPlayerLesson.prototype.onTwoPanelHandler = function() {
    console.log('mtmPlayerLesson > onTwoPanelHandler');
    // this.elExitButton.style.display = 'none';
}

// 현재 안씀 - 테스트 용
mtmPlayerLesson.prototype.onStepDownHandler = function() {
    this.clPlayerLessonProgress.stepDown();
}
// 현재 안씀 - 테스트 용
mtmPlayerLesson.prototype.onStepUpHandler = function() {
    this.clPlayerLessonProgress.stepUp();
}
// 현재 안씀 - 테스트 용
mtmPlayerLesson.prototype.onMessagePopUpHandler = function() {
    var el = document.getElementById('id-main-right-panel');
    var rect = el.getBoundingClientRect();
    var position = {};
    position.left = rect.left;
    position.width = rect.width;
    position.top = rect.top;
    position.height = rect.height;

    this.clMessagePopUp.setPosition(position);
    this.clMessagePopUp.doModal();
}

// Todo. Jstar :
// 현재 안씀 - 테스트 용 (One Panel 일때 써야함)
mtmPlayerLesson.prototype.onExitHandler = function() {
    console.log('mtmPlayerLesson : onExitHandler');
    // this._showPlayer(true);
    // this.bShowResult = false;
    this.stopLesson();
    mtvEvents.emit('OnLessonExit'); 
}

mtmPlayerLesson.prototype.onVideoCompleted = function() {
    console.log('mtmPlayerLesson : onVideoCompleted');
    // Todo. Jstar. onVideoCompleted 이중 발생
    // Todo. Jstar. 이것 말고도 iPAD or iOS 에서 Lesson 이 사라 졌는데,
    // Sound 가 나오는 현상(Backgoround Play)이 있다.
    if(this.clPlayerVideoContent && this.clPlayerVideoContent.bShow)
        setTimeout(this._videoContinueLesson.bind(this),0);
    else
        console.log('mtmPlayerLesson : onVideoCompleted --- abnormal');
        
}

// Question Card 에서 "제출" 버튼
// 문제의 답을 제출 했을때...
mtmPlayerLesson.prototype.onQuestionSubmitHandler = function() {
    
    setTimeout(this._questionSubmitLesson.bind(this),0);
}

// Question Card 에서 "오답하기" 버튼
mtmPlayerLesson.prototype.onCorrectWrongHandler = function() {
    // console.log('mtmPlayerLesson : onCorrectWrongHandler');
    this._playLessonContent();
}

mtmPlayerLesson.prototype.onContinueContentHandler = function() {

    // 이어하기가 가능 하면...
    if(this.infoResult.bContinue)
    {
        console.log('mtmPlayerLesson.prototype.onContinueContentHandler:',
        this.infoResult.iContiIndexUnit,
        this.infoResult.iContiIndexItem);

        this.player.currentIndexTotal = this._getContentLinearIndex(this.infoResult.iContiIndexUnit,
            this.infoResult.iContiIndexItem);
        
        this.player.currentUnitIndex = this.infoResult.iContiIndexUnit; 
        this.player.currentItemIndex = this.infoResult.iContiIndexItem;
        
        this.player.finalIndexTotal = this.player.currentIndexTotal;
        
        this._playLessonContent();    
    }
}

mtmPlayerLesson.prototype.onConfirmContentHandler = function() {

}

mtmPlayerLesson.prototype.onNextStepHandler = function() {
    console.log('mtmPlayerLesson > onNextStepHandler : ');
    this._continueStep();    
}

mtmPlayerLesson.prototype.onConfirmHandler = function() {
    console.log('mtmPlayerLesson > onConfirmHandler : ');
    // this._continueStep();
    this._showPlayer(false);
    this._showResult(true);
}

mtmPlayerLesson.prototype.onEventSolutionHandler = function(eData) {
    console.log('mtmPlayerLesson > onEventSolutionHandler : ', eData);
    this.player.videoId = eData.videoId;
    this.player.videoTime = eData.time;
    this.player.bSolutionPlay = true;
    this._startVideo();
    // this.clPlayerVideoContent
}

mtmPlayerLesson.prototype.onUnseenVideoClickHandler = function(tX,tY,value){
    if(this.options.modeStudent)   // 학생 모드
        return;
    console.log('mtmPlayerLesson > onUnseenVideoClickHandler :',tX,tY,value);
    
    var index = this._getContentLinearIndex(tY,tX);
    // 범위를 벗어나면 땡 !
    if(tY >= this.player.lesson_unit.length)
        return;
    if(tX >= this.player.lesson_unit[tY].content_list.length)
        return;
    if(this.player.lesson_unit[tY].content_list[tX].video == '1')
    {
        // 선생님이 안 본 비디오(Unseen Video)를
        // 본 비디오 (Seen Video) 로 교체 할 수 있음.
        console.log('mtmPlayerLesson > onUnseenVideoClickHandler : ', index);
    }
}

// Lesson 결과 Table 을 Click 했을때, 적절한 해당 기능을 한다.
mtmPlayerLesson.prototype.onCellClickHandler = function(tX,tY,value){
    console.log('mtmPlayerLesson > onCellClickHandler : ',tX,tY,value);
    
    this.player.currentIndexTotal = this._getContentLinearIndex(tY,tX);

    if(tY >= this.player.lesson_unit_new.length)
        return;
    if(tX >= this.player.lesson_unit_new[tY].types.length)
        return;
    
    // 비디오 // 이미 본 비디오 ? 안 본 비디오
    if(this.player.lesson_unit_new[tY].types[tX] == 'v')    // video
    {
        this.player.currentUnitIndex = tY; 
        this.player.currentItemIndex = tX;
        
        // 이미 본 비디오 ? 아직 안 본 비디오 ?
        if(this.player.currentIndexTotal > this.player.finalIndexTotal)
            this.player.finalIndexTotal = this.player.currentIndexTotal;
        
    }
    else if(value == '?')   // 아직 안한 레슨 문제
    {
        
        this.player.currentUnitIndex = tY; 
        this.player.currentItemIndex = tX;
        this.player.finalIndexTotal = this.player.currentIndexTotal;
        
    }
    else if(value == 'O')   // 이미 맞춘 레슨 문제
    {
        this.player.currentUnitIndex = tY; 
        this.player.currentItemIndex = tX;
    }
    else if(value == 'X')   // 이미 틀린 레슨 문제
    {
        this.player.currentUnitIndex = tY; 
        this.player.currentItemIndex = tX;
        
    }
    this._playLessonContent();
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////// API /////////////////////////////////
mtmPlayerLesson.prototype.setPlayOptions = function(options) 
{
    this.playOptions = options;

    this.player.student_id = this.playOptions.student_id;
    this.player.class_id = this.playOptions.class_id;
    this.player.course_id = this.playOptions.course_id;
    this.player.clinic_id = this.playOptions.clinic_id;
    this.player.in_id = this.playOptions.in_id;
    this.player.in_index = this.playOptions.in_index;
    this.player.base_id = this.playOptions.base_id;
    this.player.ref_id = this.playOptions.ref_id;

    this.player.content_id = this.playOptions.content_id;
    this.player.content_type = this.playOptions.content_type;
    this.player.course_type = this.playOptions.course_type;

    this.player.cdate = this.playOptions.cdate;
    this.player.udate = this.playOptions.udate;
    
    

    if(this.player.course_type == 0)
        this.player.clinic_id = this.player.course_id;
    // prepare Data
    // this._prepareLessonDataNew(eData);

    this.player.lesson_unit_new = this.playOptions.units;
    this.player.lesson_content_new = this.playOptions.content_list;
    this.player.lesson_result_new = this.playOptions.results;

    this.player.lesson_unit_valids = this.playOptions.valids;

    if(!this.player.lesson_result_new)
        this.player.lesson_result_new = [];
    if(!this.player.lesson_unit_valids)
    {
        this.player.lesson_unit_valids = [];
        for(var i=0;i<this.player.lesson_unit_new.length;i++)
            this.player.lesson_unit_valids.push(1);
    }

    // console.log('mtmPlayerLesson.>.setPlayOptions :',this.player.lesson_result_new);
    // prepare Title
    this._prepareTitle(this.playOptions.title);

    // prepare Result
    this._prepareResult();

    // prepare Progress
    this._prepareProgress();

}

mtmPlayerLesson.prototype.startLesson = function() {
    
    this.player.bSolutionPlay = false;
    
    if(this.player.lesson_unit_new.length == 0)
        return;

    // 가장 최근에 한 작업
    if((this.player.finalProcessIndex == -1) && (this.options.modeStudent))  // and student mode
    {
        
        console.log('mtmPlayerLesson > startLesson (new Start): ', this.player.finalProcessIndex );
        // 테스트를 처음하는 것...
        this.player.currentProcessIndex = 0;
        
        // Lesson Progress 는 보여준다.
        // this._prepareLessonProgressNew();
        // 레슨을 시작한다.
        // this._continueLessonContent();
        this.player.currentUnitIndex = this.player.finalUnitIndex;
        this.player.currentItemIndex = this.player.finalItemIndex;
        
        // this.clLessonResultTable.show(false);
        this._playLessonContent();    
    }
    else
    {
        console.log('mtmPlayerLesson > startLesson (old Start) : ', this.player.finalProcessIndex );
        
        // Lesson Player 는 감춘다.
        this._showPlayer(false);
        
        // 조건을 보고 Show on/off 를 결정
        // if(this.options.modeStudent == 1)  // student mode
        this.clLessonSubmitAction.show(true);
        this.clPlayerLessonProgress.show(true);
        // 표를 보여준다.
        // 여기서 무엇을 해야 하나를 선택할 수 있게 한다.
        this.clLessonResultTable.show(true);
    }
    return;
}

mtmPlayerLesson.prototype.show = function(bShow) {
    if(bShow)
        this.elThis.style.display = 'block';
    else
        this.elThis.style.display = 'none';
}

mtmPlayerLesson.prototype.stopLesson = function() {
    // 
    this.player.bSolutionPlay = false;
    if(this.clPlayerVideoContent)
        this.clPlayerVideoContent.stop();
}

// eData.contentunit_list
// eData.contentresult_list 
///////////////////////// 나중에 하나의 Video Player 로 운용하기 위한 API //////////////
mtmPlayerLesson.prototype.removePlayerVideo = function() {
    if(this.clPlayerVideo)
    {
        this.elThis.removeChild(this.clPlayerVideo.elThis);
        this.clPlayerVideo = null;
    }
}

// 기능이 없네....
mtmPlayerLesson.prototype.requestPlayerVideo = function() {
    if(this.options && this.options.requestPlayerVideo)
        return this.options.requestPlayerVideo();

    return null;
}

mtmPlayerLesson.prototype.setOptionInfo = function(data) {
    
    this.player.student_id = data.student_id;
    this.player.class_id = data.class_id;
    this.player.course_id = data.course_id;
    this.player.content_id = data.content_id;
    this.player.content_type = data.type;
    
}

////////////////////////////////////////////////////////////////////////
///////////////////////////////// URL //////////////////////////////////
mtmPlayerLesson.prototype._aurlUpdateLessonResultInfo = function(result) {
    if(!this.options.modeStudent)   // no student
        this.options.onSkipContent = false;
}

mtmPlayerLesson.prototype._urlUpdateLessonResultInfo = function() {
    var self = this;
    var formData = new FormData();
    
    formData.append('csrfmiddlewaretoken', csrftoken);
    
    formData.append('student_id',this.player.student_id);
    formData.append('class_id',this.player.class_id);
    formData.append('course_id',this.player.course_id);
    // formData.append('clinic_id',this.player.clinic_id);
    formData.append('in_id',this.player.in_id);
    if(this.player.clinic_id)
        formData.append('clinic_id',this.player.clinic_id);
    formData.append('content_id',this.player.content_id);
    formData.append('content_type',this.player.content_type);
    formData.append('course_type',this.player.course_type);
    formData.append('base_id',this.player.base_id);
    formData.append('ref_id',this.player.ref_id);

    formData.append('properties',JSON.stringify(this.player.lesson_result_new));

    // console.log('mtmPlayerLesson > _urlUpdateLessonResultInfo : ',
    //         this.player.student_id,
    //         this.player.class_id,   // missing
    //         this.player.course_id,  
    //         this.player.clinic_id,  // missing
    //         this.player.content_id,
    //         this.player.content_type,
    //         this.player.course_type,    // missing
    //         this.player.lesson_result_new);

    formData.append('progress',this.player.progress);
    formData.append('point',this.player.point);
    var cDate = new Date();
    var cdate = cDate.toISOString();
    if(!this.player.cdate)
        this.player.cdate = cdate;
    this.player.udate = cdate;
    
    formData.append('cdate', this.player.cdate);
    formData.append('udate', this.player.udate);
    
    var eData = {};
    eData.progress = this.player.progress;
    eData.point = this.player.point;
    eData.content_id = this.player.content_id;
    eData.course_type = this.player.course_type;
    // Update Study Container Panel
    mtoEvents.emit('OnChangeProgressPoint',eData);

    var url = "/st/updatestudyresultinfo/";
    // var url = "/st/updatelessonresultinfo/";
    
    $.ajax({
        url: url,
        data: formData,
        processData: false,
        contentType: false,
        method: 'POST',
        type: 'POST',
        cache: false,
        
        success: function(res){
            self._aurlUpdateLessonResultInfo(res.result);
        },
        error : function() {
            window.location.href = '/';
        }
    }); // end of ajax

}
