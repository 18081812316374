import {StudyCourseBuilder} from '../../../static/js/yland/study-course-builder.js';
export function Element(course){
    this.subject = course.subject
    this.course_id = course.course_id
    this.title = course.title
    this.content_id = course.content_id
    this.type = course.type
    this.img = course.img
    this.elThis = ''

    this.clCourse = new StudyCourseBuilder();
    $('#course-list').append(this.clCourse.elThis);


    this.init()
}

Element.prototype.init = function(){
    var $course = $('<div>').prop({
        class : 'swiper-slide w-[300px] p-2 cursor-pointer'
    })

    var $img = $('<img>')
    $img.attr('src', `/static/img/thumnail/${this.img}`)
    $img.attr('class', `hover:scale-105`)

    $course.append($img)
    $course.on('click', () => this.clickEvent())

    this.elThis = $course

}

Element.prototype.clickEvent = function(){
    console.log(this.title)
    this.activateModal()
}

Element.prototype.activateModal = async function() {
	try{
		await this.clCourse.onChangeCourseContentHandler({'course_id':this.course_id, 'content_id':this.content_id,'title':this.title,'content_type':this.type})
		Swal.fire({
        html: this.clCourse.elThis,
        showCloseButton: true, // 우측 상단 X버튼
        showConfirmButton: false, // Confirm 버튼 안보이게하기
        allowOutsideClick: false, // 모달 밖을 눌러도 모달 안닫히기
        });
    }catch{

    }
}