export var mtoCommon = {
    version : 3,
    year : 0,
    subTable : true,
    themeVersion : 2,   // 2 or 3 
    defaultThemeIndex : 3,  
    agencyImg : '',
    // 선생님 타입
    teacher_type : 0,
    // 0 == 정상 선생님 (담임, 교과목)
    // 3 == 운영자 (선생님)
    // 선생님 id
    teacher_id : '',
    // 선생님 권한 : 담임
    // teacher_class : false,
    // 선생님 권한 : 과목
    teacher_subject : false,

    suggestionMin : 2,
    bClassSelected : false,
    
    stapp_info : {
                    contentListView : 0,    // whole list
                    defaultThemeIndex : 3,  
                },
    tcapp_info : {
        param1: 0,
        param2: 1,
    },

    bSchedulerPersonal : false,

    // Clinic Related Configure
    bClinicClass : false,
    bClinicPersonal : false,
    bClinicWrongAuto : false,
    iClinicWrongTime : '',

    // Test Auto Clinic from Simulation Date and Result
    bSimulDate : false,
    dateSimul : '2023-10-30',
    pointSimul : 70,
    
};